/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";
import { GridStack } from "gridstack";

window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * GridStack is for the Dashboard
 */

window.GridStack = GridStack;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import "./echo";

/**
 * Swapy
 */
import { createSwapy } from 'swapy'

window.createSwapy = createSwapy;

/**
 * sign pad
 *
 * This package usually pushes its js to public/vendor since
 * this is unwanted, we copy it to this place.
 *
 * So if the package updates, and has some breaking changes,
 * probably you only have to replace the content of this folder.
 */
import './third-party/sign-pad/sign-pad.min.js';