Alpine.data("page", (initialBackgroundClassString = '', themeSettings = null) => {
  return {
    transitionDelay: 1,
    previousBackgroundClassString: null,
    newBackgroundId: "new-background",
    overlayBackgroundId: "overlay-background",
    transitionClasses: {
      initial: "transition-opacity duration-1000 opacity-0",
      add: "opacity-100",
      remove: "opacity-0",
    },
    init() {
      if (initialBackgroundClassString !== null) {
        this.onUpdateLayoutBackground(initialBackgroundClassString);
      }

      document.addEventListener("livewire:initialized", () =>
        this.livewireInitializedEvent(),
      );
    },
    livewireInitializedEvent() {
      Livewire.on("update-layout-background", ({ layoutBackground }) =>
        this.onUpdateLayoutBackground(layoutBackground),
      );
    },
    onUpdateLayoutBackground(layoutBackground) {
      if (themeSettings !== null) {
        const bgColors = themeSettings['bg_colors'] ?? null;

        console.log(bgColors);

        if(bgColors === null) {
          return;
        }

        const gradient = `linear-gradient(to bottom right, ${bgColors.join(", ")})`;

        this.addBackgroundDiv(
          'themeBackgroundGradientColor',
          gradient,
          true
        );

        this.addBackgroundDiv(
          'themeBackgroundGradientOverlayColor',
          gradient,
          true
        );

        return;
      }

      // Check if we already updated it on this session
      let alreadyNewBackground = document.getElementById(this.newBackgroundId);

      // Get layoutBackground by Name
      let newBackgroundClassString = layoutColors.get(layoutBackground);

      // Contains the background, wich is currently set (or used to be for this page)
      if (!alreadyNewBackground) {
        this.addBackgroundDiv(
          "previous-background",
          this.previousBackgroundClassString,
        );
      }

      // Only continue with this if new isn't previous
      if (newBackgroundClassString !== this.previousBackgroundClassString) {
        if (alreadyNewBackground) {
          this.newBackgroundId = `background-${Math.round(Math.random() * 99)}`;
        }

        let divClassList = this.addBackgroundDiv(
          this.newBackgroundId,
          this.transitionClasses.initial,
        );

        setTimeout(() => {
          divClassList.add(
            this.transitionClasses.add,
            ...this.stringToArray(newBackgroundClassString),
          );

          divClassList.remove(this.transitionClasses.remove);

          if (alreadyNewBackground) {
            alreadyNewBackground.remove();
          }
        }, this.transitionDelay); // This should correspond to the transition duration

        this.previousBackgroundClassString = newBackgroundClassString;
      }

      document.getElementById(this.overlayBackgroundId)?.remove();
      this.addBackgroundDiv(
        this.overlayBackgroundId,
        layoutColors.getOverlay(layoutBackground),
      );
    },
    stringToArray(string, separator = " ") {
      return string.split(separator).filter(Boolean);
    },
    addBackgroundDiv(divId, divClass, inlineStyle = false) {
      let newDiv = document.createElement("div");

      newDiv.id = divId;

      newDiv.className = `fixed top-0 left-0 right-0 bottom-0 inset-0 z-[-1]`;
      if(inlineStyle) {
        newDiv.style.background = divClass;
      } else {
        newDiv.className += ` ${divClass}`;
      }

      document.body.append(newDiv);

      return newDiv.classList;
    },
  };
});
